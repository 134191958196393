import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { FiPlus } from "react-icons/fi";
import Masonry from "react-masonry-css";
import Fade from "react-reveal/Fade";

const breakpointColumnsObj = {
  default: 5,
  1100: 4,
  700: 3,
  500: 2,
};

const Portfolio = () => {
  return (
    <SimpleReactLightbox>
      <div className="portfolio-filter-01">
        <Tabs>
          <TabList className="filter d-flex flex-wrap justify-content-start">
            <Tab>All</Tab>
            <Tab>GoDutch</Tab>
            <Tab>YourStory</Tab>
            <Tab>TopQuiz</Tab>
            <Tab>MobileBaazi</Tab>
            <Tab>BingeTales</Tab>
          </TabList>
          {/* End tablist */}

          <SRLWrapper>
            <TabPanel>
              <div className="portfolio-content">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                        <h5>GoDutch</h5>
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/godutch/gd-2.jpg"
                        className="gallery-link"
                      >
                        <Fade>
                          <img
                            src="img/portfolio/godutch/gd-2.jpg"
                            alt="GoDutch"
                          />
                        </Fade>
                      </a>
                      {/* End gallery link */}
                    </div>
                  </div>

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                        <h5>YourStory</h5>
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/yourstory/ys-4.jpg"
                        className="gallery-link"
                      >
                        <Fade>
                          <img
                            src="img/portfolio/yourstory/ys-4.jpg"
                            alt="YourStory"
                          />
                        </Fade>
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>

                  {/* grid item  */}

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                        <h5>TopQuiz</h5>
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/topquiz/tq-1.jpg"
                        className="gallery-link"
                      >
                        {" "}
                        <Fade>
                          <img
                            src="img/portfolio/topquiz/tq-1.jpg"
                            alt="TopQuiz"
                          />
                        </Fade>
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>

                  {/* grid item  */}

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                        <h5>MobileBaazi</h5>
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/mobilebaazi/mb-1.jpg"
                        className="gallery-link"
                      >
                        {" "}
                        <Fade>
                          <img
                            src="img/portfolio/mobilebaazi/mb-1.jpg"
                            alt="MobileBaazi"
                          />
                        </Fade>
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>

                  {/* grid item  */}

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                        <h5>BingeTales</h5>
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/bingetales/bt-1.png"
                        className="gallery-link"
                      >
                        {" "}
                        <Fade>
                          <img
                            src="img/portfolio/bingetales/bt-1.png"
                            alt="BingeTales"
                          />{" "}
                        </Fade>
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>
                  {/* grid item  */}

                </Masonry>
              </div>
              {/* End list wrapper */}
            </TabPanel>

            <TabPanel>
              <div className="portfolio-content">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/godutch/gd-1.jpg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/godutch/gd-1.jpg"
                          alt="GoDutch"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/godutch/gd-2.jpg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/godutch/gd-2.jpg"
                          alt="GoDutch"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/godutch/gd-3.jpg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/godutch/gd-3.jpg"
                          alt="GoDutch"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/godutch/gd-4.jpg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/godutch/gd-4.jpg"
                          alt="GoDutch"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>
                </Masonry>
                {/* grid item  */}
              </div>

              {/* End list wrapper */}
            </TabPanel>

            <TabPanel>
              <div className="portfolio-content">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/yourstory/ys-4.jpg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/yourstory/ys-4.jpg"
                          alt="YourStory"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/yourstory/ys-3.jpg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/yourstory/ys-3.jpg"
                          alt="YourStory"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/yourstory/ys-1.jpg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/yourstory/ys-1.jpg"
                          alt="YourStory"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/yourstory/ys-2.jpg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/yourstory/ys-2.jpg"
                          alt="YourStory"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/yourstory/ys-5.jpg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/yourstory/ys-5.jpg"
                          alt="YourStory"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>
                </Masonry>
                {/* grid item  */}
              </div>

              {/* End list wrapper */}
            </TabPanel>

            <TabPanel>
              <div className="portfolio-content">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/topquiz/tq-1.jpg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/topquiz/tq-1.jpg"
                          alt="TopQuiz"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>

                  {/* grid item  */}

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/topquiz/tq-2.jpg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/topquiz/tq-2.jpg"
                          alt="TopQuiz"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>

                  {/* grid item  */}

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/topquiz/tq-3.jpg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/topquiz/tq-3.jpg"
                          alt="TopQuiz"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/topquiz/tq-4.jpg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/topquiz/tq-4.jpg"
                          alt="TopQuiz"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>
                  {/* grid item  */}
                </Masonry>
              </div>
              {/* End list wrapper */}
            </TabPanel>

            <TabPanel>
              <div className="portfolio-content">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/mobilebaazi/mb-1.jpg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/mobilebaazi/mb-1.jpg"
                          alt="MobileBaazi"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>

                  {/* grid item  */}

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/mobilebaazi/mb-2.jpg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/mobilebaazi/mb-2.jpg"
                          alt="MobileBaazi"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>

                  {/* grid item  */}

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/mobilebaazi/mb-3.jpg"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/mobilebaazi/mb-3.jpg"
                          alt="MobileBaazi"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>
                  {/* grid item  */}
                </Masonry>
              </div>
              {/* End list wrapper */}
            </TabPanel>

            <TabPanel>
              <div className="portfolio-content ">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/bingetales/bt-4.png"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/bingetales/bt-4.png"
                          alt="BingeTales"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>

                  {/* grid item  */}

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/bingetales/bt-3.png"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/bingetales/bt-3.png"
                          alt="BingeTales"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>

                  {/* grid item  */}

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/bingetales/bt-1.png"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/bingetales/bt-1.png"
                          alt="BingeTales"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>

                  {/* grid item  */}

                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href="img/portfolio/bingetales/bt-2.png"
                        className="gallery-link"
                      >
                        <img
                          src="img/portfolio/bingetales/bt-2.png"
                          alt="BingeTales"
                        />
                      </a>
                      {/* End gallery link */}

                      {/* End .portfolio-icon */}
                    </div>
                  </div>

                  {/* grid item  */}
                </Masonry>
              </div>
              {/* End list wrapper */}
            </TabPanel>
          </SRLWrapper>
          {/* End tabpanel */}
        </Tabs>
      </div>
    </SimpleReactLightbox>
  );
};

export default Portfolio;
