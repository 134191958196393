import React from "react";
import Social from "../Social";
import Testimonials from "../testimonial/TestimonialAnimation";
import Services from "../service/ServiceAnimation";
import Awards from "../award/AwardsAnimation";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

const About = () => {
  return (
    <>
      <section id="about" className="section theme-light dark-bg">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-6 col-lg-4">
              <div className="about-me">
                <div className="img">
                  <div className="img-in">
                    <img src="img/about/about-me.jpg" alt="about image" />
                  </div>
                  {/* <Zoom>
                    <Social />
                  </Zoom> */}
                  {/* End social icon */}
                </div>
                {/* End img */}
                <div className="info">
                  <Fade bottom>
                    <p>Software Developer</p>
                    <h3>Lakshay Parnami</h3>
                  </Fade>
                </div>
                {/* End info */}
              </div>
              {/* End about-me */}
            </div>
            {/* End col */}

            <div className="col-lg-7 ml-auto">
              <div className="about-info">
                <div className="title">
                  <h3>Biography</h3>
                </div>
                <div className="about-text">
                  <Fade bottom>
                    <p>
                      I'm a Front-end Developer with over 4 years of
                      experience. I'm from Jaipur. I code and create applications for
                      amazing people around the world. I like to work
                      with new people.
                      <p> New People New Experiences.</p>
                    </p>
                  </Fade>
                </div>
                <div className="info-list">
                  <Fade bottom>
                    <div className="row">
                      <div className="col-sm-6">
                        <ul>
                          <li>
                            <label>Name: </label>
                            <span>Lakshay Parnami</span>
                          </li>
                          <li>
                            <label>Birthday: </label>
                            <span>17th May 1995</span>
                          </li>
                          <li>
                            <label>Age: </label>
                            <span>29 years</span>
                          </li>
                          <li>
                            <label>Address: </label>
                            <span>Jaipur, Rajasthan</span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-sm-6">
                        <ul>
                          <li>
                            <label>Phone: </label>
                            <span>+91 8107 958922</span>
                          </li>
                          <li>
                            <label>Email: </label>
                            <span>laksh.parnami@gmail.com</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
            {/* End col */}
          </div>

          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>

          <div className="title">
            <h3>Testimonials.</h3>
          </div>

          <Testimonials />
          {/* End Testimonaial */}
        </div>
      </section>
    </>
  );
};

export default About;
