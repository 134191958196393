import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Fade from "react-reveal/Fade";

export default function SimpleSlider() {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    margin: 30,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [

    {
      imageName: "lajeet",
      desc: ` He has been an amazing team player. 
      Strong with quick solutions, along with professional in implementing an optimised solution.
      He always guns up for the code & team. Definitely a Leader, in making...`,
      reviewerName: "Lajeet Adhikary",
      designation: "Tech lead at YourStory.",
    },
    {
      imageName: "jitesh",
      desc: ` He has been a pleasure to work with, bringing his attention to detail to every project. His communication and people skills are excellent, and He always has some very innovative ideas.`,
      reviewerName: "Jitesh Shinde",
      designation: "Special Project Manager at CrimzonCode Technologies",
    },
    {
      imageName: "akshit-ostwal",
      desc: ` In the time I've known him, Lakshay has consistently been a strong employee capable of taking control of the entire project and executing to the fullest. 
      As well, he's a pleasure to work with. 
      Lakshay is my senior and I love working with him, got to learn a lot from him throughout the whole duration of internship and job`,
      reviewerName: "Akshit Ostwal",
      designation: "Co-Founder at Orange Wallet",
    },
    {
      imageName: "pranali",
      desc: ` Fantastic work! I am just impressed by his service quality and working strategy.
      He has great expertise, is dedicated, attentive, talented and care much about the product needs. Highly recommended.`,
      reviewerName: "Pranali Sawakhande",
      designation: "Project associate at CrimzonCode.",
    },
  ];

  return (
    <div className="testimonial-wrapper">
      <Slider {...settings}>
        {TestimonilContent.map((val, i) => (
          <div key={i}>
            <div className="testimonial-01 media">
              <Fade bottom>
                <div className="avatar">
                  <img
                    src={`img/testimonial/${val.imageName}.jpg`}
                    alt="review comments"
                  ></img>
                </div>
                <div className="media-body">
                  <p>{val.desc}</p>
                  <h6>{val.reviewerName}</h6>
                  <span>{val.designation}</span>
                </div>
              </Fade>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
