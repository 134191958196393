import React from "react";

const skillContent = [
  {
    name: "Android",
    percent: "92",
    numberPercent: "92",
  },
  {
    name: "Flutter",
    percent: "75",
    numberPercent: "75",
  },
  {
    name: "React JS",
    percent: "62",
    numberPercent: "62",
  },
];

const Skills = () => {
  return (
    <>
      <div className="skill-wrapper">
        {skillContent.map((skill, i) => (
          <div className="skill-lt" key={i}>
            <h6>{skill.name}</h6>
            <span>{skill.percent}%</span>
            <div className="skill-bar">
              <div
                className="skill-bar-in"
                style={{ width: skill.numberPercent + "%" }}
              ></div>
            </div>
          </div>
          // End skill-lt
        ))}
      </div>
    </>
  );
};

export default Skills;
