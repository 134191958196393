import React from "react";
import Routes from "./router/Routes";
import ScrollToTop from "./components/ScrollToTop";
import AnimatedCursor from "react-animated-cursor";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const App = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyA3Mk3AD9fgLSGYLpDt2xgcPKiHS5VdjBU",
    authDomain: "lakshay-parnami-web.firebaseapp.com",
    projectId: "lakshay-parnami-web",
    storageBucket: "lakshay-parnami-web.appspot.com",
    messagingSenderId: "475104748235",
    appId: "1:475104748235:web:60e6597a27ede8f66e714e",
    measurementId: "G-GTSBW47WH2"
  };
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  logEvent(analytics, 'app.js init', { "init": "init" });

  return (
    <>
      <ScrollToTop />
      <Routes />
      <AnimatedCursor
        innerSize={16}
        outerSize={78}
        color="200, 14, 14"
        outerAlpha={0.2}
        innerScale={0.5}
        outerScale={0.5}
      />
    </>
  );
};

export default App;
