import React from "react";
import Fade from "react-reveal/Fade";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaGithub,
  FaMediumM,
} from "react-icons/fa";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/laksh.c.parnami" },
  { Social: <FaGithub />, link: "https://github.com/lakshparnami/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/lakshay.parnami/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/in/lakshay-parnami/" },
  { Social: <FaMediumM />, link: "https://lakshay-parnami.medium.com/" },
];

const Footer = () => {
  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-6 my-2">
          <div className="nav justify-content-center justify-content-md-start">
            <Fade bottom>
              {SocialShare.map((val, i) => (
                <a
                  key={i}
                  href={`${val.link}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {val.Social}
                </a>
              ))}{" "}
            </Fade>
          </div>
          {/* End .nav */}
        </div>
        {/* End .col */}

        {/* <div className="col-md-6 my-2 text-center text-md-end">
          <Fade bottom>
            <p>
              © {new Date().getFullYear()} copyright{" "}
              <a
                href="https://themeforest.net/user/ib-themes/portfolio"
                target="_blank"
                rel="noreferrer"
              >
                ib-themes
              </a>{" "}
              all right reserved
            </p>{" "}
          </Fade>
        </div> */}
        {/* End .col */}
      </div>
      {/* End .row */}
    </>
  );
};

export default Footer;
