import React from "react";
import Skills from "../skills/Skills";
import Fade from "react-reveal/Fade";

const resumeContent = [
  {
    jobPosition: `SDE-3 Android`,
    jobType: `Full-Time`,
    jobDuration: `Sept 2022 - Present`,
    timeDuraton: `Full-Time`,
    compnayName: "Porter",
    jobDescription: [`Developed the payments & locations platform of Porter Driver and Customer Android Applications`],
  },
  {
    jobPosition: `Sr. Software Developer`,
    jobType: `Full-Time | Remote`,
    jobDuration: `Jan 2022 - Sept 2022`,
    timeDuraton: `Full-Time`,
    compnayName: "Godutch Technologies Pvt. Ltd.",
    jobDescription: [`Participated in the development of GoDutch React Native Android Application`, `Led the development of a new flutter project`],
  },
  {
    jobPosition: `Sr. Software Developer`,
    jobType: `Full-Time | Remote`,
    jobDuration: `Feb 2020 - Jan 2022`,
    timeDuraton: `Full-Time`,
    compnayName: "YourStory Media Pvt. Ltd.",
    jobDescription: [`Designed and Developed YourStory Native Android Application`, `Designed and Developed Flutter Application from scratch`, `Designed and Developed an upcoming react product from scratch`],
  },
  {
    jobPosition: `Software Developer - Android`,
    jobType: `Full-Time`,
    jobDuration: `Feb 2018 - Feb 2020`,
    timeDuraton: `CrimzonCode`,
    compnayName: "CrimzonCode",
    jobDescription: [`Managed and developed the TopQuiz, BattleKing and MobileBaazi Quiz Gaming Applications.`],
  },
  {
    jobPosition: `Head Of Product Development`,
    jobType: `Full-Time`,
    jobDuration: `Sep 2017 – Dec 2017`,
    timeDuraton: `Full Time`,
    compnayName: "Paperwork Consulting Pvt. Ltd.",
    jobDescription: [`Developed the BingeTales Book-reading Application`, `BingeTales brings #stories back in your life in the most distinctive way. All the content on the app is organised in eight categories viz. #Horror, #Teen, #Mythology, #Humour, #Mystery, #Children and #Classics - BingeTales is the first of its kind.`],
  },
  {
    jobPosition: `Android Developer`,
    jobType: `Intern`,
    jobDuration: `July 2017 – Sept 2017`,
    timeDuraton: `Full Time`,
    compnayName: "ClinMD",
    jobDescription: [`Developed the Prescription writing Application app and interfaced it with Smart pen using bluetooth`],
  },
];

const achievementContent = [

  {
    title: `Featured in newspaper`,
    date: `15 September 2021`,
    description: `Featured in FirstIndia newspaper on Engineer's day, with under the title 'Magicians with tools'.`,
    link: `https://firstindia.co.in/article-view/jaipur/15092021/32555`,
    linkTitle: `Article Link`,
  },
  {
    title: `National Level Android Application Development Winner`,
    date: `March 2016`,
    description: `Secured first position (National Level) in the final round of Android
    Championship, Utkraanti in association with Chemclave’16 IIT Madras organized by Wingfotech Excellence held at IIT Madras.`,
    link: null,
    linkTitle: null,
  },

];

const educatonContent = [
  {
    passingYear: "2017",
    degreeTitle: "B.Tech Computer Science",
    instituteName: "Amity University Jaipur",
  },
  {
    passingYear: "2013",
    degreeTitle: "CBSE Class XII",
    instituteName: "Maheshwari Public School, Jawahar Nagar, Jaipur",
  },
  {
    passingYear: "2011",
    degreeTitle: "CBSE Class X",
    instituteName: "Maheshwari Public School, Jawahar Nagar, Jaipur",
  },
];

const Resume = () => {
  return (
    <>
      <section id="resume" className="section">
        <div className="container">
          <div className="title">
            <h3>Experience.</h3>
          </div>
          {/* End title */}
          <div className="resume-box">
            {resumeContent.map((val, i) => (
              <Fade bottom key={i}>
                <div className="resume-row">
                  <div className="row">
                    <div className="col-md-4 col-xl-3">
                      <div className="rb-left">
                        <h6>{val.jobPosition}</h6>
                        <label style={{ color: `white` }}>{val.jobType}</label>
                        <p>{val.jobDuration}</p>
                      </div>
                    </div>
                    <div className="col-md-8 col-xl-9">
                      <div className="rb-right">
                        <h6>{val.compnayName}</h6>
                        <p>{val.jobDescription.map((description) => { return <p>{description}</p>; })}</p>

                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            ))}
          </div>

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL + "img/border-dark.png"
                })`,
            }}
          ></div>
          {/* End separated */}
          <div className="title">
            <h3>Achievements.</h3>
          </div>
          {/* End title */}
          <div className="resume-box">
            {achievementContent.map((val, i) => (
              <Fade bottom key={i}>
                <div className="resume-row">
                  <div className="row">
                    <div className="col-md-8 col-xl-9">
                      <div className="rb-right">
                        <h6>{val.title}</h6>
                        <label style={{ color: `white` }}>{val.date}</label>
                        <p>{val.description}</p>
                        {val.link && <b><a href={val.link} target='_blank'>{val.linkTitle}</a></b>}
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            ))}
          </div>

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL + "img/border-dark.png"
                })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>Education & Skills</h3>{" "}
          </div>

          <div className="row align-items-center">
            <div className="col-lg-4 m-15px-tb">
              <ul className="aducation-box">
                {educatonContent.map((val, i) => (
                  <li key={i}>
                    <Fade bottom>
                      <span>{val.passingYear}</span>
                      <h6>{val.degreeTitle} </h6>
                      <p>{val.instituteName}</p>{" "}
                    </Fade>
                  </li>
                ))}
              </ul>
            </div>
            {/* End .col */}

            <div className="col-lg-7 ml-auto m-15px-tb">
              <Fade>
                <Skills />{" "}
              </Fade>
            </div>
            {/* End .col */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Resume;
